var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inspectPointImport-wrapper" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("v-button", {
            attrs: { text: "返回" },
            on: { click: _vm.previous },
          }),
        ],
        1
      ),
      _c(
        "import-template",
        { attrs: { uploadUrl: _vm.uploadUrl, downloadUrl: _vm.downloadUrl } },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _c("div", [
              _c("p", [_vm._v("以下皆为必填字段")]),
              _c("p", [_vm._v("1、姓名：最多10位；")]),
              _c("p", [_vm._v("2、手机号：11位数字；")]),
              _c("p", [_vm._v("3、组织级别：平台、公司、项目；")]),
              _c("p", [_vm._v("4、所属组织：必须是表中已有的组织名称；")]),
              _c("p", [_vm._v("请在excel中按以上顺序填写数据")]),
              _c("p", [_vm._v("最大支持10MB的excel文件，超过请拆分导入")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }