// 获取内部联系人列表
const getInternalListURL = `${API_CONFIG.shopBaseURL}maillist/inMaillist`
// 获取外部联系人列表
const getExternalListURL = `${API_CONFIG.shopBaseURL}maillist/outMaillist`
// 删除
const deleteURL = `${API_CONFIG.shopBaseURL}maillist/deleteMaillist`
// 导入内部联系人
const importInternalURL = `${API_CONFIG.shopBaseURL}maillist/importInMaillist`
// 导入外部联系人
const importExternalURL = `${API_CONFIG.shopBaseURL}maillist/importOutMaillist`
// 保存内部联系人
const saveInURL = `${API_CONFIG.shopBaseURL}maillist/saveInMaillist`
// 编辑内部联系人
const updateInURL = `${API_CONFIG.shopBaseURL}maillist/updateInMaillist`
// 保存外部联系人
const saveOutURL = `${API_CONFIG.shopBaseURL}maillist/saveOutMaillist`
// 查询联系人
const queryURL = `${API_CONFIG.shopBaseURL}maillist/getMaillistDetail`
// 编辑外部联系人
const updateOutURL = `${API_CONFIG.shopBaseURL}maillist/updateOutMaillist`
// 导出内部联系人
const exportInURL = `${API_CONFIG.shopBaseURL}maillist/exportInMaillist`
// 导出外部联系人
const exportOutURL = `${API_CONFIG.shopBaseURL}maillist/exportOutMaillist`
// 查询组织
const getOrgURL = `${API_CONFIG.baseURL}serverRoleAction!getOrganize.action`

export {
  getInternalListURL,
  getExternalListURL,
  deleteURL,
  importInternalURL,
  importExternalURL,
  saveInURL,
  updateInURL,
  queryURL,
  saveOutURL,
  updateOutURL,
  exportInURL,
  exportOutURL,
  getOrgURL
}
